import React from 'react'
import PropTypes from 'prop-types'
import { Badge } from 'react-bootstrap'

import styles from './style.module.scss'
import noImage from '@assets/images/placeholder/article-placeholder.jpg'
import videoIcon from '@assets/images/icon/play-icon.png'
import { convertDt24h, dateCheck, getArticleImg, getImgURL, convertHMS } from '@shared-submodule/utils'
import { BLUR_DATA_URL_BASE64, DEFAULT_BLOG_READ } from '@shared-submodule/sm-constants'
import CustomLink from '@shared/components/customLink'
import clockIcon from '@assets/images/icon/clock.svg'
import MyImage from '@shared/components/myImage'
import dynamic from 'next/dynamic'
import Calendar from '@assets/images/placeholder/calendar.svg'
import View from '@assets/images/placeholder/view.svg'
import Trans from 'next-translate/Trans'
const ArticleStyles = dynamic(() => import('@shared/components/article/articleStyle'))

function ArticleGrid({ data, isVideo, isFullCard, index, isFirstImg, isVideoTab, videoThumbnail, showDuration }) {
  return (
    <ArticleStyles>
      {(articleStyles) => (
        <article className={`${articleStyles.article} ${styles.articleGrid} ${isVideo && articleStyles.video} ${isVideo && styles.video} ${isFullCard && articleStyles.fullCard} ${isFullCard && styles.fullCard} d-flex flex-column position-relative`} id={data?._id}>
          <CustomLink href={`/${data?.oSeo?.sSlug}` || ''} prefetch={false}>
            <a className={`${articleStyles.postimg} ${styles.postimg} d-block block-img`}>
              {
                isVideo && (
                  <MyImage
                    priority={!!isFirstImg}
                    src={videoThumbnail ? getImgURL(videoThumbnail) : (data?.sThumbnailUrl ? getImgURL(data.sThumbnailUrl) : noImage)}
                    alt={data?.sTitle}
                    blurDataURL={BLUR_DATA_URL_BASE64}
                    placeholder='blur'
                    height='80'
                    width='128'
                    layout='responsive'
                    sizes='(max-width: 767px) 120px, (max-width: 991px) 180px, (max-width: 1190px) 200px, 240px'
                  />
                )
              }
              {
                !isVideo && (
                  <MyImage
                    priority={!!isFirstImg}
                    src={getArticleImg(data)?.sUrl || noImage}
                    alt={getArticleImg(data)?.sText || data?.sSrtTitle}
                    placeholder='blur'
                    blurDataURL={BLUR_DATA_URL_BASE64}
                    height='80'
                    width='128'
                    layout='responsive'
                    sizes='(max-width: 767px) 120px, (max-width: 991px) 180px, (max-width: 1190px) 200px, 240px'
                  />
                )
              }
            </a>
          </CustomLink>
          {
            isVideo &&
            <>
              <div className={`${articleStyles.videoTime} d-flex g-10`}>
                <MyImage src={clockIcon} alt='clock' width={12} height={16} />
                <div>
                  {convertHMS(data?.nDurationSeconds)}
                </div>
              </div>
              <div className={`${articleStyles.videoIcon} ${styles.videoIcon} position-absolute`}>
                <MyImage src={videoIcon} alt='video' />
              </div>
            </>
          }
          <div className={`${styles.postDesc} ${articleStyles.postDesc} ${isVideo && styles.isVideo}`}>
            {
              data?.category && !isVideo(
                <Badge bg='primary' className={`${styles.badge} ${articleStyles.badge} ${data?.isVideo && 'video-badge'} mb-2`}>
                  {data?.category}
                </Badge>
              )
            }
            {
              isVideo ? (
                <>
                  <h1 className='small-head mb-2 h4'>
                    <CustomLink href={`/${data?.oSeo?.sSlug}` || ''} prefetch={false}>
                      <a>{isVideo ? data?.sTitle : data?.sSrtTitle || data?.sTitle}</a>
                    </CustomLink>
                  </h1>
                  {data?.dPublishDate && (
                    <div className={styles.time}>

                      <MyImage
                        src={Calendar}
                        alt="Calendar Icon"
                        height="18"
                        width="18" />
                      <div className='mt-1 ms-2'> {convertDt24h(dateCheck(data?.dPublishDate))} </div>
                    </div>
                  )}
                </>
              ) : (
                <>
                  <h2 className={`${styles.heading4}`}>
                    <CustomLink href={`/${data?.oSeo?.sSlug || ''}`} prefetch={false}>
                      <a>{isVideo ? data?.sTitle : data?.sSrtTitle || data?.sTitle}</a>
                    </CustomLink>
                  </h2>
                  <div className='d-flex'>
                    {(data?.dPublishDate || showDuration || data?.nDuration) && (
                      <div className={`${articleStyles.articleInfo} d-flex`}>
                        {(data?.dPublishDisplayDate || data?.dPublishDate) && (
                          <span className="d-flex align-items-center">
                            <MyImage
                              src={Calendar}
                              alt="Calendar Icon"
                              height="18"
                              width="18" />
                            <span className='ms-1'>
                              {convertDt24h(dateCheck(data?.dPublishDisplayDate || data?.dPublishDate))}
                            </span>
                          </span>
                        )}
                        {showDuration && data?.nDuration && <span className="d-flex align-items-center">
                          <MyImage
                            src={View}
                            alt="Views"
                            height="18"
                            width="18" />
                          <span className='ms-1'>
                            {data?.nViewCount}
                          </span>
                          {/* {data?.nOViews > -1 ? data?.nOViews : DEFAULT_BLOG_READ} */}
                        </span>}
                      </div>
                    )}
                  </div>
                </>
              )
            }
          </div>
        </article>
      )
      }
    </ArticleStyles >
  )
}
ArticleGrid.propTypes = {
  isVideo: PropTypes.bool,
  isFullCard: PropTypes.bool,
  isFirstImg: PropTypes.bool,
  isVideoTab: PropTypes.bool,
  showDuration: PropTypes.bool,
  data: PropTypes.object,
  index: PropTypes.number,
  videoThumbnail: PropTypes.string
}

export default ArticleGrid
